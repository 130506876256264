import * as React from 'react'
import { graphql } from 'gatsby'

import { Main } from '../components/pages/main'
import { useSiteMetadata } from '../hooks/useSiteMetadata'

const AllEnglishPostsList = (props: any) => {
  const siteMetadata = useSiteMetadata()
  return <Main {...props} siteMetadata={siteMetadata} />
}

export default AllEnglishPostsList

export const pageQuery = graphql`
  query ($postsLimit: Int!, $postsOffset: Int!, $language: String!) {
    archives: allMarkdownRemark(filter: { frontmatter: { language: { eq: $language } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            tags
            cover {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  placeholder: BLURRED
                  webpOptions: { quality: 90 }
                  width: 100
                  quality: 90
                )
              }
            }
          }
        }
      }
    }
    allMarkdownRemark(
      limit: $postsLimit
      skip: $postsOffset
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { language: { eq: $language } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            summary
            category
            language
            cover {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  placeholder: BLURRED
                  webpOptions: { quality: 90 }
                  width: 700
                  height: 320
                  quality: 90
                )
              }
            }
          }
        }
      }
    }
  }
`
